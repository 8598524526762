import React from "react"

import beliefs1Img from "../images/beliefs-1.svg"
import we3Img from "../images/we3.svg"
import beliefs2Img from "../images/beliefs-2.svg"
import beliefs3Img from "../images/beliefs-3.svg"
import beliefs4Img from "../images/beliefs-4.svg"



import SEO from "../components/SEO/seo"
import { siteData } from "../data/siteData"
import Layout from "../components/Layout"
import FreeTrialLesson from "../components/FreeTrialLesson"

import CustomersSay from "../components/CustomersSay"
import SpotlightBanner from "../components/SpotlightBanner"
import ScrollAnimation from "react-animate-on-scroll"
import "animate.css/animate.min.css"
import { useStaticQuery, graphql, Link } from "gatsby"
import Img from "gatsby-image"

const AboutUs = () => {
  const data = useStaticQuery(graphql`
    query SpotlightBannerAboutUsImageQuery {
      spotlightBannerImageImage: file(
        relativePath: { eq: "about-banner.jpg" }
      ) {
        childImageSharp {
          fluid( maxWidth: 1440, 
            quality: 100,
            srcSetBreakpoints: [485, 815, 975, 1440]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        }
      }
      aboutEventEasterImage: file(relativePath: { eq: "about-events-easter.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 655, quality: 100, srcSetBreakpoints: [345, 455, 564, 655]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        } 
      }
      aboutEvent1minChallengeImage: file(relativePath: { eq: "about-events-1-min-challenge.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 655, quality: 100, srcSetBreakpoints: [345, 455, 564, 655]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        } 
      }
      aboutEventHalloweenImage: file(relativePath: { eq: "about-events-halloween.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 655, quality: 100, srcSetBreakpoints: [345, 455, 564, 655]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        } 
      }
      aboutEventXmasImage: file(relativePath: { eq: "about-events-xmas.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 655, quality: 100, srcSetBreakpoints: [345, 455, 564, 655]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        } 
      }
      staffDanImage: file(relativePath: { eq: "staff-avatar-1.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 553, quality: 100, srcSetBreakpoints: [232, 289, 390, 553]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        } 
      }
      staffMaikoImage: file(relativePath: { eq: "staff-avatar-2.jpg" }) {
        childImageSharp {
          fluid(maxWidth: 553, quality: 100, srcSetBreakpoints: [232, 289, 390, 553]) {
            ...GatsbyImageSharpFluid_withWebp
          }
        } 
      }
    }
  `)
  return (
    <>
      <Layout>
        <SEO metaData={siteData.aboutusPage} siteData={siteData}/>

        {/* <!-- Banner section --> */}

        <SpotlightBanner
          bannerImage={data.spotlightBannerImageImage.childImageSharp.fluid}
          title="スクールについて"
          //title="ABOUT US"
          //subtitle="スクールについて"
        />

        <ScrollAnimation
          //offset={80}
          animateIn="fadeInUp"
          duration={1}
          animateOnce={true}
        >
          <section className="classlink-section">
            <ul className="classlink-list font-24">
              <li className="classlink-item bg-yellow">
                <Link to="/about-us#features" className="link-white">
                  ５つの特色
                </Link>
              </li>
              <li className="classlink-item bg-green">
                <Link to="/about-us#staff" className="link-white">
                  スタッフ
                </Link>
              </li>
              <li className="classlink-item bg-blue">
                <Link to="/about-us#events" className="link-white">
                  スクールのイベント
                </Link>
              </li>

              <li className="classlink-item bg-red">
                <Link to="/about-us#voice" className="link-white">
                  お母さんのコメント
                </Link>
              </li>



            </ul>
          </section>
        </ScrollAnimation>

        {/* <!-- Welcome text section --> */}

        <ScrollAnimation
          //offset={80}
          animateIn="fadeInUp"
          duration={1}
          animateOnce={true}
        >
          <section className="welcome-section inner-padding">
            <div className="container">
              <div className="row">
                <div className="col-xs-12 text-center">

                  <div className="static-text">
                    <p>
                      {" "}
                      アメリカンイングリッシュハウスでは、コミュニケーションツールとしてますますニーズの高まる英語を楽しく効果的に習得することを目指しています。ネイティブ講師ならではの利点を生かしたカリキュラムでレベルアップをサポートしていきたいと考えています。手の届く目標を設定し、子どもたちの「できた、もっと英語を知りたい」というやる気を育みつつ、英語をもっと身近に感じ広い世界に興味と関心を抱いてもらう、そんな一端を担えたらと思っています。
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </ScrollAnimation>



        {/* <!-- Our Beliefs --> */}
        <section id="features" className="weare-section inner-padding">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <ScrollAnimation
                  offset={80}
                  animateIn="fadeInUp "
                  duration={1}
                  animateOnce={true}
                >
                  <h3 className="title">５つの特色</h3>
                </ScrollAnimation>
              </div>
             
                <div   className="weare-flex">
                <ScrollAnimation
                offset={80}
                animateIn="fadeInUp"
                duration={1}
                animateOnce={true}
                className="col-xs-12 col-sm-4 weare-grid"
              
              >

                  <img
                    src={beliefs1Img}
                    alt="少人数制"
                    className="we-img sm-img"
                  />
                  <h4 className="weare-title">少人数制</h4>
                  <p>
                    １クラスの定員が最大８名までの少人数制です。講師と話す機会も多く、一人一人に目が行き届くので英語がしっかり身につきます。講師やクラスメイトともすぐに打ち解けて和気あいあいとした雰囲気でレッスンができるのも少人数ならではのメリットです。
                  </p>
                </ScrollAnimation>
                <ScrollAnimation
                offset={80}
                animateIn="fadeInUp"
                duration={1}
                animateOnce={true}
                className="col-xs-12 col-sm-4 weare-grid"
              
              >
                  <img
                    src={we3Img}
                    alt="ネイティブ講師："
                    className="we-img sm-img"
                  />
                  <h4 className="weare-title">ネイティブ講師</h4>
                  <p>
                    ネイティブ講師に英語を習うメリットは、正しい発音と自然な表現が身につくこと、外国の方とでも臆することなく話ができるといったコミュニケーション能力の向上、講師を通じて文化や人種の違いに気付くこと、体感することなどがあります。
                  </p>
                </ScrollAnimation>

                <ScrollAnimation
                offset={80}
                animateIn="fadeInUp"
                duration={1}
                animateOnce={true}
                className="col-xs-12 col-sm-4 weare-grid"
              
              >

            
                  <img
                    src={beliefs2Img}
                    alt="スピーキング発表会："
                    className="we-img sm-img"
                  />
                  <h4 className="weare-title">スピーキング発表会</h4>
                  <p>
                    年に１０回、生徒同士がクラスメイトやママたちの前で英語の会話を披露する小さな会を実施しています。人前で英語を話す訓練のようなもので、みんなの前で上手にできればやる気もグングン伸びていきます！
                  </p>
                  </ScrollAnimation>

                  <ScrollAnimation
                offset={80}
                animateIn="fadeInUp"
                duration={1}
                animateOnce={true}
                className="col-xs-12 col-sm-4 weare-grid"
              
              >
                  <img
                    src={beliefs3Img}
                    alt="小学生からの文法学習："
                    className="we-img sm-img"
                  />
                  <h4 className="weare-title">小学生からの文法学習</h4>
                  <p>
                    小学校での英語教科化に伴い、文章の読み書きに欠かせない文法学習が小学生にも必要となってきました。テキストは小学生向けにわかりやすい説明がされており、よく使用する動詞や英文を習うことができます。
                  </p>
                  </ScrollAnimation>

                  <ScrollAnimation
                offset={80}
                animateIn="fadeInUp"
                duration={1}
                animateOnce={true}
                className="col-xs-12 col-sm-4 weare-grid"
              
              >
                  <img
                    src={beliefs4Img}
                    alt="楽しいレッスン："
                    className="we-img sm-img"
                  />
                  <h4 className="weare-title">楽しいレッスン</h4>
                  <p>
                    レッスンの最初や最後にさまざまな種類のカードやiPad、ビンゴやボードゲームなどを使用し、子どもたちを飽きさせない工夫をしています。英語が楽しい、もっと英語を知りたい、できるようになりたい、と思ってもらえるように笑顔あふれるレッスンを用意しています。
                  </p>
                  </ScrollAnimation>

                </div>
          
            </div>
          </div>
        </section>

        {/* <!-- Staff --> */}
        <section id="staff" className="staff-section section-wide inner-padding bg-gray">
          <div className="container">
            <div className="row">
              <div className="staff-wrapper">
                <h3 className="title">スタッフ</h3>
                <ul className="staff-flex-wrapper">
                  <li className="staff-flex">
                    <ScrollAnimation
                      offset={80}
                      animateIn="fadeInUp"
                      duration={1}
                      animateOnce={true}
                      className="staff-cols"
                    >
                      <div className="staff-img-wrap red-box">
                        <Img
                          fluid={data['staffDanImage'].childImageSharp.fluid}
                          className="staff-img" alt="Dan"
                        />
                      </div>
                    </ScrollAnimation>
                    <ScrollAnimation
                      offset={80}
                      animateIn="fadeInUp"
                      duration={1}
                      animateOnce={true}
                      className="staff-cols"
                    >
                      <div className="heading5-text">Dan (ダン)</div>
                      <p>
                        誰とでもすぐに仲良くなれる気さくな性格で、子どもたちにもよくイジられています♬「何事もやって見なきゃ分からない」がモットーで若い頃から好奇心旺盛、さまざまなスポーツにチャレンジしたり、バックパッカーで旅に出たり、大学生の頃は色々なバイトを経験してきました。小さなことは気にせず、いつもポジティブな先生です。
                      </p>
                      <p>
                        アメリカ、バーモント州生まれ。バーモント州立大学教育学部卒。米国教員免許取得。TESOL資格取得。
                      </p>
                      <p>
                        [ダン先生のHistory in Japan]
                        ２００１年に来日以降、大手英会話スクールや公立小中学校で英語講師として経験を積み、２００８年より海部郡大治町長牧で「リトルリバーイングリッシュスクール」、２０１１年に海部郡大治町三本木へ移転し自宅にて「アメリカンイングリッシュハウス」を始めました。
                      </p>
                    </ScrollAnimation>
                  </li>
                  <li className="staff-flex">
                    <ScrollAnimation
                      offset={80}
                      animateIn="fadeInUp"
                      duration={1}
                      animateOnce={true}
                      className="staff-cols"
                    >
                      <div className="staff-img-wrap blue-box">
                        <Img
                          fluid={data['staffMaikoImage'].childImageSharp.fluid}
                          className="staff-img" alt="Maiko"
                        />
                      </div>
                    </ScrollAnimation>
                    <ScrollAnimation
                      offset={80}
                      animateIn="fadeInUp"
                      duration={1}
                      animateOnce={true}
                      className="staff-cols"
                    >
                      <div className="heading5-text">Maiko (マイコ)</div>
                      <p>
                        幼い頃から外国に憧れ、今に至るまでずっと英語と共に過ごしてきました。洋楽と映画とネコが大好きです。レッスンのサポート、スクールの窓口業務、運営全般の担当で、小学生の文法学習では日本語でポイントを説明するなど必要に応じてレッスンのお手伝いをしています。お困りごとやご相談などありましたら気軽に声をかけてくださいね。
                      </p>
                      <p>
                        愛知県生まれ。青山学院大学文学部英米文学科卒。TOEIC960点。
                      </p>
                    </ScrollAnimation>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- School Events --> */}
        <section id="events" className="school-events-section inner-padding">
          <div className="container">
            <div className="row">
              <div className="school-events-wrapper flex justify-content-center">
                <h3 className="title col-xs-12">スクールのイベント</h3>
                <ScrollAnimation
                  offset={80}
                  animateIn="fadeInUp"
                  duration={1}
                  animateOnce={true}
                  className="col-xs-12 col-sm-6 school-events-cols"
                >
                  <div className="se-block">
                    <div className="se-block-img">
                      <Img
                        fluid={data['aboutEventEasterImage'].childImageSharp.fluid}
                        className="se-img" alt=""
                      />
                    </div>
                    <div className="se-block-content bg-blue">
                      <ScrollAnimation
                        offset={80}
                        animateIn="fadeInUp"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="heading5-text">
                          <strong> 春：イースター（２年に一度）</strong>
                        </div>
                      </ScrollAnimation>
                      <ScrollAnimation
                        offset={80}
                        animateIn="fadeInUp"
                        duration={1}
                        animateOnce={true}
                      >
                        <p>
                          アメリカで春の訪れを感じるイベントと言えばタマゴが主役のイースターです！本来はキリスト教のお祭りですが、日本でもカラフルなタマゴやウサギなどのかわいいイベントとして知られつつあります。アメリカンイングリッシュハウスでもエッグカラーリングやエッグハントなどでお祭り気分を盛り上げます♬
                        </p>
                      </ScrollAnimation>
                    </div>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation
                  offset={80}
                  animateIn="fadeInUp"
                  duration={1}
                  animateOnce={true}
                  className="col-xs-12 col-sm-6 school-events-cols"
                >
                  <div className="se-block">
                    <div className="se-block-img">
                      <Img
                        fluid={data['aboutEvent1minChallengeImage'].childImageSharp.fluid}
                        className="se-img" alt=""
                      />

                    </div>
                    <div className="se-block-content bg-blue">
                      <ScrollAnimation
                        offset={80}
                        animateIn="fadeInUp"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="heading5-text">
                          <strong>
                            {" "}
                            春：１分間チャレンジゲーム大会（２年に一度）
                          </strong>
                        </div>
                      </ScrollAnimation>
                      <ScrollAnimation
                        offset={80}
                        animateIn="fadeInUp"
                        duration={1}
                        animateOnce={true}
                      >
                        <p>
                          イースターと交互に行う春のイベントが１分間チャレンジゲーム大会です。制限時間１分のゲームをクリアし賞金を獲得するアメリカの番組「A
                          minute to win
                          it」をヒントにしました。お玉やピンポン球、ストッキングなど身近にある材料を使用して子供用にアレンジしたゲームを１分以内にクリアしていきます。もちろんもらえるのは賞金ではなく、お菓子です！
                        </p>
                      </ScrollAnimation>
                    </div>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation
                  offset={80}
                  animateIn="fadeInUp"
                  duration={1}
                  animateOnce={true}
                  className="col-xs-12 col-sm-6 school-events-cols"
                >
                  <div className="se-block">
                    <div className="se-block-img">
                      <Img
                        fluid={data['aboutEventHalloweenImage'].childImageSharp.fluid}
                        className="se-img" alt=""
                      />
                    </div>
                    <div className="se-block-content bg-blue">
                      <ScrollAnimation
                        offset={80}
                        animateIn="fadeInUp"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="heading5-text">
                          <strong>秋：ハロウィン</strong>
                        </div>
                      </ScrollAnimation>
                      <ScrollAnimation
                        offset={80}
                        animateIn="fadeInUp"
                        duration={1}
                        animateOnce={true}
                      >
                        <p>
                          死者をお迎えする日という意味を持つハロウィンですが日本ではすっかりコスプレイベントとして定着しました。生徒のみんなもスタッフも好きなコスチュームを着てとてもにぎやかです♬イベント中はアメリカンイングリッシュハウスもお化け屋敷に様変わり！スケルトンパズルやハロウィンクイズなどさまざまなゲームでたくさんのお菓子をゲット♬
                        </p>
                      </ScrollAnimation>
                    </div>
                  </div>
                </ScrollAnimation>
                <ScrollAnimation
                  offset={80}
                  animateIn="fadeInUp"
                  duration={1}
                  animateOnce={true}
                  className="col-xs-12 col-sm-6 school-events-cols"
                >
                  <div className="se-block">
                    <div className="se-block-img">
                      <Img
                        fluid={data['aboutEventXmasImage'].childImageSharp.fluid}
                        className="se-img" alt=""
                      />
                    </div>
                    <div className="se-block-content bg-blue">
                      <ScrollAnimation
                        offset={80}
                        animateIn="fadeInUp"
                        duration={1}
                        animateOnce={true}
                      >
                        <div className="heading5-text">
                          <strong>冬：クリスマス</strong>
                        </div>
                      </ScrollAnimation>
                      <ScrollAnimation
                        offset={80}
                        animateIn="fadeInUp"
                        duration={1}
                        animateOnce={true}
                      >
                        <p>
                          アメリカでは大切な家族と一緒に過ごすクリスマス。モミの木の下に置かれた沢山のプレゼントを一つずつ開けていく光景が目に浮かびます。アメリカンイングリッシュハウスのクリスマスでは一緒にケーキを食べたり、クリスマスカードを作ったり、ビンゴなどのゲームをして年の終わりを実感しながらしっとり過ごします♬
                        </p>
                      </ScrollAnimation>
                    </div>
                  </div>
                </ScrollAnimation>
              </div>
            </div>
          </div>
        </section>

        {/* <!-- Free Trial Lesson --> */}
        <FreeTrialLesson title="無料体験レッスン" button="お申し込みはこちら" />

        {/* <!-- What Our Customers Say --> */}
        <CustomersSay title="お母さんたちからのコメント" id="voice"/>
      </Layout>
    </>
  )
}
export default AboutUs
